import React, {useMemo} from 'react';
import {FlexColumnSection} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {useSelector} from "react-redux";
import {getMembershipAddons, getMembershipExpirationText, getMembershipPriceText} from "../../../helpers/functions";

const MembershipInfo = (props) => {
    const { membership } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const {isMobile} = useScreenSize()

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);

    if(!membership) return null

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '15px'} flex={'unset'}>
            <span>{getMembershipPriceText(membership, currencySymbol)}</span>
            <span>{getMembershipExpirationText(membership)}</span>
            {membership.addons?.length > 0 && <div>{getMembershipAddons(membership.addons, currencySymbol)}</div>}
        </FlexColumnSection>
    );
};

export default MembershipInfo;