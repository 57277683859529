import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Formik} from "formik";
import {
    ClickableDiv,
    FlexColumnSection,
    FlexRowSection,
    GreyText,
    Header,
    HeaderLine,
    Wrapper,
    ArrowWrapper,
} from "../styles/globalStyles";
import {useDispatch, useSelector} from "react-redux";
import {
    onBack,
    renderStep,
    stepHeader,
    stepInitialValues,
    stepOptions,
    stepValidationSchema
} from "./StepOptions";
import useFlow from "../helpers/hooks/useFlow";
import {updateCurrStep} from "@arboxappv4/shared/src/redux/stepperSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isRTL} from "../helpers/i18n";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {light, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import useScreenSize from "../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {saveFlow, showStepsProgress, overrideValues} from "./FlowOptions";
import {useLocation} from "react-router";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

const FlowManager = (props) => {
    const { flowSelection, initProps } = props;
    const flowName = useSelector(state => state.stepper.flowName)
    const steps = useSelector(state => state.stepper.steps)
    const currStep = useSelector(state => state.stepper.currStep)
    const flowRes = useSelector(state => state.stepper.flowRes)
    const asyncData = useSelector(state => state.stepper.asyncData)
    const [initialValues, setInitialValues] = useState({});
    const dispatch = useDispatch()
    const formikRef = useRef();
    const {isMobile} = useScreenSize()
    const location = useLocation();
    const navigate = useNavigate()
    useFlow({flowSelection, initProps})

    const headerHeight = useMemo(() => ![stepOptions.SUCCESS, stepOptions.PAYMENT].includes(currStep) ? 60 : 0, [currStep]);
    const validationSchema = useMemo(() => stepValidationSchema()[currStep], [currStep, asyncData]);

    useEffect(() => {
        if(steps) {
            let relevantStepsInitialValues = getInitialValues({...location.state, ...initProps})
            setInitialValues(relevantStepsInitialValues)
        } else {
            setInitialValues({})
        }
    }, [steps]);

    useEffect(() => {
        if(Object.keys(asyncData).length) {
            const newValues = Object.keys(asyncData).map(step => stepInitialValues({values: formikRef.current?.values})[step]).reduce((acc, vals) => ({...acc, ...vals}), {})
            setInitialValues({...formikRef.current?.values, ...newValues})
        }
    }, [asyncData]);

    useEffect(() => {
        if(flowRes) {
            const initVals = getInitialValues({});
            formikRef.current.resetForm({values: initVals})
            if(!flowRes.showSuccessToast) {
                dispatch(updateCurrStep(stepOptions.SUCCESS))
            }
        }
    }, [flowRes]);

    const getInitialValues = (params) => {
        let relevantStepsInitialValues = steps.reduce((acc, step) => [...acc, stepInitialValues(params)[step]],[])
        relevantStepsInitialValues = relevantStepsInitialValues.reduce((acc, values) => ({...acc, ...values}), {});
        return (flowRes || overrideValues(flowName)) ? relevantStepsInitialValues : {...relevantStepsInitialValues, ...formikRef.current?.values}
    }

    const submitStep = (values) => {
        const stepIndex = steps.indexOf(currStep);
        if(stepIndex + 1 < steps.length) {
            dispatch(updateCurrStep(steps[stepIndex + 1]))
        } else {
            console.log('save!', values)
            saveFlow(values, dispatch)
        }
    }

    const prevStep = useCallback(({setFieldValue, values}) => {
        const stepIndex = steps.indexOf(currStep);
        if(stepIndex - 1 >= 0) {
            onBack[currStep]({setFieldValue, values})
            dispatch(updateCurrStep(steps[stepIndex - 1]))
        }
    },[steps, currStep])

    const renderHeader = useCallback(({...rest}) => {
        if(steps && currStep && currStep !== stepOptions.SUCCESS) {
            const stepIndex = steps.indexOf(currStep);
            return (
                <>
                    {isMobile ?
                        <div>
                            {showStepsProgress[flowName] && <ProgressMobile width={`${((stepIndex + 1) * 100) / steps.length}%`}/>}
                            <div style={{padding: 15}}>
                                <FlexRowSection gap={'16px'}>
                                    {stepIndex > 0 && <ClickableDiv onClick={() => prevStep({...rest})}><FontAwesomeIcon icon={solid('chevron-left')} color={Colors.siteBlack} flip={isRTL() ? 'horizontal' : 'vertical'}/></ClickableDiv>}
                                    <HeaderLine>{stepHeader({initProps, ...rest})[currStep]()}</HeaderLine>
                                </FlexRowSection>
                            </div>
                        </div>
                        :
                        <FlexRowSection gap={'8px'}>
                            {stepIndex > 0 && <ArrowWrapper onClick={() => prevStep({...rest})}><FontAwesomeIcon icon={solid('chevron-left')} color={Colors.siteBlack} size={'xs'} flip={isRTL() ? 'horizontal' : 'vertical'}/></ArrowWrapper>}
                            <HeaderLine>{stepHeader({initProps, ...rest})[currStep]()}</HeaderLine>
                        </FlexRowSection>
                    }
                </>

            )
        }
    },[steps, currStep])

    const getStepComp = ({...rest}) => {
        if(currStep) {
            const target = initProps?.target ?? {}
            return renderStep[currStep]({locationParams: location.state, navigate, validationSchema, target, ...rest})
        }
    }

    return (
        <Wrapper height={'100%'}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitStep} innerRef={formikRef} enableReinitialize>
                {({ handleSubmit, ...rest }) => (
                    <div style={{width: '100%'}}>
                        {
                            //flowSelection === flowName &&
                            <>
                                {![stepOptions.SUCCESS, stepOptions.PAYMENT].includes(currStep) && <div style={{height: headerHeight}}>{renderHeader({...rest})}</div>}
                                <div style={{height: `calc(100% - ${headerHeight}px)`, overflowY: 'auto', display: 'flex', width: 'inherit'}}>{getStepComp({handleSubmit, ...rest})}</div>
                            </>
                        }
                    </div>
                )}
            </Formik>
        </Wrapper>
    );
};

export default FlowManager;

const ProgressMobile = styled.div`
    height: 3;
    width: ${({width}) => width};
    background-color: ${Colors.siteBlack};
    border-radius: ${isRTL() ? '60px 0 0 60px' : '0 60px 60px 0'}
`;