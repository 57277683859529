import React from "react";
import {stepOptions} from "./StepOptions";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {store} from "@arboxappv4/shared/src/redux/store";
import {setError, setLoadingType} from "@arboxappv4/shared/src/redux/siteSlice";
import {
    DB_DATE_FORMAT,
    LOADING_PAGE,
    SITE_REFERER,
    DB_TIME_FORMAT,
    LOADING_PAYMENT
} from "@arboxappv4/shared/src/constants/constants";
import {updateError, updateFlowRes} from "@arboxappv4/shared/src/redux/stepperSlice";
import {t} from "i18next";
import {
    getBookingCtaText,
    getBoxPageOptions,
    handleMembershipStartDate,
    onLinkClicked,
    sortCourseEventDays
} from "../helpers/functions";
import {addTokenAfterRegister} from "@arboxappv4/shared/src/redux/authSlice";
import MembershipDescription from "./steps/directPageComponents/MembershipDescription";
import MembershipInfo from "./steps/directPageComponents/MembershipInfo";
import dayjs from "dayjs";
import {
    openModal,
    SELECT_DATE_MODAL,
    SELECT_QUANTITY_MODAL,
    updateModalRes
} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {fetchUserMembershipsByBox} from "@arboxappv4/shared/src/redux/userSlice";
import {bookingTypes, membershipTypes} from "@arboxappv4/shared/src/helpers/constants";
import GroupSessionDescription from "./steps/directPageComponents/GroupSessionDescription";
import GroupSessionInfo from "./steps/directPageComponents/GroupSessionInfo";
import CourseDescription from "./steps/directPageComponents/CourseDescription";
import CourseInfo from "./steps/directPageComponents/CourseInfo";
import {pixelEvents, sendPixelEvent} from "../helpers/hooks/UsePixel";

export const flowOptions = {
    REGISTER: 'REGISTER',
    MEMBERSHIP_PURCHASE: 'MEMBERSHIP_PURCHASE',
    DIRECT_MEMBERSHIP_PURCHASE: 'DIRECT_MEMBERSHIP_PURCHASE',
    WAIVER: 'WAIVER',
    REGISTER_WITHOUT_MEMBERSHIP: 'REGISTER_WITHOUT_MEMBERSHIP',
    GROUP_SESSION: 'GROUP_SESSION',
    DIRECT_GROUP_SESSION: 'DIRECT_GROUP_SESSION',
    STAFF_APPOINTMENT: 'STAFF_APPOINTMENT',
    SPACE_APPOINTMENT: 'SPACE_APPOINTMENT',
    COURSE: 'COURSE',
    DIRECT_COURSE: 'DIRECT_COURSE',
    COURSE_CHILD_REGISTRATION: 'COURSE_CHILD_REGISTRATION',
    PRODUCT_PURCHASE: 'PRODUCT_PURCHASE',
    DIRECT_PRODUCT_PURCHASE: 'DIRECT_PRODUCT_PURCHASE',
    DIRECT_TRIAL_GROUP: 'DIRECT_TRIAL_GROUP',
}

//<editor-fold desc="init flow">

export const initFlow = (flowName) => {
    switch(flowName) {
        case flowOptions.REGISTER:
            return initRegister();
        case flowOptions.MEMBERSHIP_PURCHASE:
            return initMembershipPurchase();
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            return initDirectMembershipPurchase();
        case flowOptions.WAIVER:
            return { flowName: flowOptions.WAIVER, steps: [stepOptions.WAIVER], currStep: stepOptions.WAIVER};
        case flowOptions.REGISTER_WITHOUT_MEMBERSHIP:
            return initRegisterWithoutMembership()
        case flowOptions.GROUP_SESSION:
        case flowOptions.DIRECT_GROUP_SESSION:
            return initGroupSession(flowName)
        case flowOptions.STAFF_APPOINTMENT:
        case flowOptions.SPACE_APPOINTMENT:
            return initAppointment(flowName)
        case flowOptions.COURSE:
        case flowOptions.DIRECT_COURSE:
            return initCourse(flowName)
        case flowOptions.COURSE_CHILD_REGISTRATION:
            return initGroupRegistrationCourse()
        case flowOptions.PRODUCT_PURCHASE:
        case flowOptions.DIRECT_PRODUCT_PURCHASE:
            return initProductPurchase(flowName)
        case flowOptions.DIRECT_TRIAL_GROUP:
            return initDirectTrialGroup()
        default:
            return {};
    }
}

const initRegister = () => {
    // in the future we can get a selected membership and than we have a permanent location and no membership selection step
    const box = store.getState().site.box
    let steps;
    if(box?.digital_form_id) {
        steps = [stepOptions.PERSONAL_DETAILS, stepOptions.SELECT_MEMBERSHIP,stepOptions.WAIVER, stepOptions.PAYMENT]
    } else {
        steps = [stepOptions.PERSONAL_DETAILS, stepOptions.SELECT_MEMBERSHIP, stepOptions.PAYMENT]
    }

    return {
        flowName: flowOptions.REGISTER,
        steps: steps,
        currStep: stepOptions.PERSONAL_DETAILS
    }
}

const initRegisterWithoutMembership = () => {
    const box = store.getState().site.box
    let steps;
    if(box.digital_form_id) {
        steps = [stepOptions.PERSONAL_DETAILS, stepOptions.SELECT_MEMBERSHIP,stepOptions.WAIVER]
    } else {
        steps = [stepOptions.PERSONAL_DETAILS, stepOptions.SELECT_MEMBERSHIP]
    }

    return {
        flowName: flowOptions.REGISTER_WITHOUT_MEMBERSHIP,
        steps: steps,
    }
}

const initMembershipPurchase = () => {
    const loggedIn = store.getState().auth.loggedIn
    return {
        flowName: flowOptions.MEMBERSHIP_PURCHASE,
        steps: [stepOptions.SELECT_MEMBERSHIP, stepOptions.PAYMENT],
        currStep: stepOptions.SELECT_MEMBERSHIP,
        flags: {loggedIn}
    }
}

const initDirectMembershipPurchase = () => {
    const loggedIn = store.getState().auth.loggedIn
    return {
        flowName: flowOptions.DIRECT_MEMBERSHIP_PURCHASE,
        steps: [stepOptions.DIRECT_ITEM, stepOptions.PAYMENT],
        currStep: stepOptions.DIRECT_ITEM,
        flags: {loggedIn}
    }
}

const initGroupSession = (flowName) => {
    const loggedIn = store.getState().auth.loggedIn
    const box = store.getState().site.box
    let steps;
    if(loggedIn) {
        steps = [stepOptions.GROUP_SESSIONS]
    } else {
        steps = box?.digital_form_id ? [stepOptions.GROUP_SESSIONS, stepOptions.PERSONAL_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.GROUP_SESSIONS, stepOptions.PERSONAL_DETAILS, stepOptions.PAYMENT]
    }
    return {
        flowName: flowName,
        steps: steps,
        currStep: stepOptions.GROUP_SESSIONS,
        flags: {loggedIn}
    }
}

const initDirectTrialGroup = () => {
    const box = store.getState().site.box
    const loggedIn = store.getState().auth.loggedIn
    const steps = box?.digital_form_id ? [stepOptions.TRIAL_GROUP_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.TRIAL_GROUP_DETAILS, stepOptions.PAYMENT]

    return {
        flowName: flowOptions.DIRECT_TRIAL_GROUP,
        steps: steps,
        currStep: stepOptions.TRIAL_GROUP_DETAILS,
        flags: {loggedIn}
    }
}

const initAppointment = (flowName) => {
    const loggedIn = store.getState().auth.loggedIn
    const box = store.getState().site.box
    let steps;
    const hasGroupStep = box.boxes_settings?.some(setting => setting.prop_name === 'appointmentShowGroupStep' && setting.properties && setting.properties.active) ?? false
    const extraStepsBefore = hasGroupStep ? [stepOptions.GROUP_SERVICE_TYPE] : []
    if(loggedIn) {
        steps = [...extraStepsBefore, stepOptions.SERVICE_TYPE, stepOptions.AVAILABILITY_SLOT]
    } else {
        steps = box?.digital_form_id ? [...extraStepsBefore, stepOptions.SERVICE_TYPE, stepOptions.AVAILABILITY_SLOT, stepOptions.PERSONAL_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [...extraStepsBefore, stepOptions.SERVICE_TYPE, stepOptions.AVAILABILITY_SLOT, stepOptions.PERSONAL_DETAILS, stepOptions.PAYMENT]
    }
    return {
        flowName: flowName,
        steps: steps,
        currStep: steps[0],
        flags: {loggedIn, serviceReady: false}
    }
}

const initCourse = (flowName) => {
    const loggedIn = store.getState().auth.loggedIn
    const box = store.getState().site.box
    let steps;
    if(loggedIn) {
        steps = [stepOptions.COURSES, stepOptions.PAYMENT]
    } else {
        steps = box?.digital_form_id ? [stepOptions.COURSES, stepOptions.PERSONAL_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.COURSES, stepOptions.PERSONAL_DETAILS, stepOptions.PAYMENT]
    }
    return {
        flowName: flowName,
        steps: steps,
        currStep: stepOptions.COURSES,
        flags: {loggedIn, serviceReady: false, courseInfo: null, isChildRegistration: false, isNewChild: false}
    }
}

const initGroupRegistrationCourse = () => {
    const loggedIn = store.getState().auth.loggedIn
    const box = store.getState().site.box
    const userBox = store.getState().user.activeUsersBoxes
    const flags = store.getState().stepper.flags ?? {}

    let steps;
    if(loggedIn) {
        //If user is head of a group and the group has at one user besides the head
        const children = userBox.group_connection?.group_members ? userBox.group_connection.group_members.filter(connection => connection.id !== userBox.group_connection?.users_boxes_id) : []
        if(userBox.group_connection?.head && children.length > 0) {
            steps = [stepOptions.COURSES, stepOptions.COURSE_CHILD_SELECTION, stepOptions.PAYMENT]
        } else {
            steps = box.digital_form_id ? [stepOptions.COURSES, stepOptions.COURSE_CHILD_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.COURSES, stepOptions.COURSE_CHILD_DETAILS, stepOptions.PAYMENT]
        }
    } else {
        steps = box.digital_form_id ? [stepOptions.COURSES, stepOptions.COURSE_PARENT_DETAILS, stepOptions.COURSE_CHILD_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.COURSES, stepOptions.COURSE_PARENT_DETAILS, stepOptions.COURSE_CHILD_DETAILS, stepOptions.PAYMENT]
    }
    return {
        flowName: flowOptions.COURSE_CHILD_REGISTRATION,
        steps: steps,
        flags: {...flags, loggedIn}
    }
}


const initProductPurchase = (flowName) => {
    const loggedIn = store.getState().auth.loggedIn
    const box = store.getState().site.box
    let steps;
    if(loggedIn) {
        steps = [stepOptions.SHOP, stepOptions.PAYMENT]
    } else {
        steps = box?.digital_form_id ? [stepOptions.SHOP, stepOptions.PERSONAL_DETAILS, stepOptions.WAIVER, stepOptions.PAYMENT] : [stepOptions.SHOP, stepOptions.PERSONAL_DETAILS, stepOptions.PAYMENT]
    }
    return {
        flowName: flowName,
        steps: steps,
        currStep: stepOptions.SHOP,
        flags: {loggedIn}
    }
}

//</editor-fold>

//<editor-fold desc="save flow">

export const saveFlow = (values, dispatch) => {
    const flowName = store.getState().stepper.flowName
    switch(flowName) {
        case flowOptions.REGISTER:
        case flowOptions.REGISTER_WITHOUT_MEMBERSHIP:
            registerUser(values, dispatch)
            break;
        case flowOptions.MEMBERSHIP_PURCHASE:
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            membershipPurchase(values, dispatch);
            break;
        case flowOptions.GROUP_SESSION:
        case flowOptions.DIRECT_GROUP_SESSION:
        case flowOptions.STAFF_APPOINTMENT:
        case flowOptions.SPACE_APPOINTMENT:
            bookUserToSession(values, dispatch)
            break;
        case flowOptions.WAIVER:
            signBoxWaiver(values, dispatch)
            break;
        case flowOptions.COURSE:
        case flowOptions.DIRECT_COURSE:
        case flowOptions.COURSE_CHILD_REGISTRATION:
            bookToCourse(values, dispatch)
            break;
        case flowOptions.PRODUCT_PURCHASE:
        case flowOptions.DIRECT_PRODUCT_PURCHASE:
            purchaseProduct(values, dispatch)
            break;
        case flowOptions.DIRECT_TRIAL_GROUP:
            bookTrialClass(values, dispatch)
            break;
        default:
            break;
    }
}

const purchaseProduct = async (values, dispatch) => {
    const loggedIn = store.getState().auth.loggedIn
    if(loggedIn) {
        membershipPurchase(values, dispatch)
    } else {
        registerUser({...values, isProduct: true}, dispatch)
    }
}

const membershipPurchase = async (values, dispatch) => {
    try {
        dispatch(setLoadingType(LOADING_PAYMENT))
        const box = store.getState().site.box
        const cart = values.payment_info.cart.reduce((acc, item) => {
            const cartItem = values.cart?.find(i => i.id === item.id)
            if(cartItem) return [...acc, {...cartItem, ...item}]
            return [...acc, item]
        },[])
        const params = {
            boxes_id: box.id,
            cart: cart,
            payment_info: values.payment_info
        }
        const res = await apiAction(`payments/purchase`, 'post', params);
        dispatch(updateFlowRes(res.data))
        dispatch(fetchUserMembershipsByBox({boxId: box.id, active: true}))
        handlePurchasePixel(cart)
        dispatch(setLoadingType(null))
    } catch (error) {
        dispatch(updateError(error.error?.messageToUser ?? t('payment-error-screen-message')))
        dispatch(setLoadingType(null))
    }
}

const bookToCourse = async (values, dispatch) => {
    try {
        dispatch(setLoadingType(LOADING_PAYMENT))
        const {params, route} = getInfoForCourseBooking(values)
        console.log('bookToCourse', params)
        const res  = await apiAction(route, 'post', params);
        if(route === 'payments/purchase') {
            dispatch(updateFlowRes(res?.data))
        } else if(route === 'registerUser') {
            // res=true when the parent is already logged in, so in any other case it's a new user and we'll want to log him in
            if((typeof res === 'object') && res?.token) dispatch(addTokenAfterRegister(res))
            dispatch(updateFlowRes(res ?? true))
        }
        handlePurchasePixel(params.cart)
        dispatch(setLoadingType(null))
    } catch (error) {
        console.log('error', error)
        dispatch(updateError(error.error?.messageToUser ?? t('payment-error-screen-message')))
        dispatch(setLoadingType(null))
    }
}

const getInfoForCourseBooking = (values) => {
    const loggedIn = store.getState().auth.loggedIn
    const flags = store.getState().stepper.flags
    const userBox = store.getState().user.activeUsersBoxes
    let params = {}
    let route = loggedIn ? 'payments/purchase' : 'registerUser'
    let course = flags.courseInfo;

    const cart = values.payment_info.cart.reduce((acc, item) => {
        console.log('item', item, course)
        if(course?.id === item.series_id) return [...acc, {...item, start: item.start ?? course.start_date, end: course.end_date, series_id: course.id, register_group_member: flags.isChildRegistration}]
        return [...acc, item]
    },[])
    params = {
        boxes_id: course.box_fk,
        cart: cart,
        payment_info: values.payment_info,
        isChildRegistration: flags.isChildRegistration
    }
    if(flags.isChildRegistration) {
        if(loggedIn) {
            if(values.child.child_id) {
                params.group_info = values.child
                route = 'payments/purchase'
            } else {
                params.parent = { id: userBox.user_fk}
                params.child = values.child
                route = 'registerUser'
            }
        } else {
            params.child = values.child
            params.parent = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone: values.phone,
                birthday: values.birthday,
                allow_mailing_list: values.allow_mailing_list,
                allow_sms: values.allow_sms,
                lead_id: values.lead_id,
                customFields: values.customFields,
                gender: values.gender
            }
            route = 'registerUser'
        }
        params.locations_box_fk = values.locations_box_fk ?? values.course?.locations_box_fk
        params.digitalFormInfo = values.digitalFormInfo ?? null
    } else {
        params = {...params, ...values}
    }
    return {params, route};
}

const registerUser = async (values, dispatch) => {
    try {
        dispatch(setLoadingType(LOADING_PAYMENT))
        const box = store.getState().site.box
        const cart = values.payment_info?.cart.reduce((acc, item) => {
            const cartItem = values.cart?.find(i => i.id === item.id)
            if(cartItem) return [...acc, {...cartItem, ...item}]
            return [...acc, item]
        },[])
        const params = {
            ...values,
            boxes_id: box.id,
            cart: values.continueWithoutMembership ? null : cart
        }
        const res = await apiAction(`registerUser`, 'post', params);
        const flowRes = values.cart?.map(item => ({membership_types: item}))
        dispatch(updateFlowRes({...res, memberships: flowRes}))
        if(!values.isProduct) dispatch(addTokenAfterRegister(res))
        dispatch(setLoadingType(null))
        if(values.continueWithoutMembership) {
            sendPixelEvent(pixelEvents.COMPLETE_REGISTRATION_NO_MEMBERSHIP)
        } else {
            handlePurchasePixel(cart)
        }
    } catch (error) {
        console.log('error', error)
        dispatch(updateError(error.error?.messageToUser ?? t('payment-error-screen-message')))
        dispatch(setLoadingType(null))
    }
}

const bookUserToSession = async (values, dispatch) => {
    try {
        const loggedIn = store.getState().auth.loggedIn
        dispatch(setLoadingType(LOADING_PAYMENT))
        if (loggedIn) {
            await handleExisingUserActions(values, dispatch)
        } else {
            await handleBookAndRegisterNewUser(values,dispatch)
        }
        dispatch(setLoadingType(null))
    } catch(error) {
        dispatch(setLoadingType(null))
        console.log('bookUserToSession error', error)
        dispatch(updateError(error.error?.messageToUser ?? ''))
    }
}

const bookTrialClass = async (values, dispatch) => {
    try {
        dispatch(setLoadingType(LOADING_PAYMENT))
        await handleBookAndRegisterNewUser(values,dispatch, true)
        dispatch(setLoadingType(null))
    } catch(error) {
        dispatch(setLoadingType(null))
        console.log('bookTrialClass error', error)
        dispatch(updateError(error.error?.messageToUser ?? ''))
    }
}

const signBoxWaiver = async (values, dispatch) => {
    try {
        dispatch(setLoadingType(LOADING_PAYMENT))
        const res = await apiAction('digitalForm', 'post', values.digitalFormInfo);
        dispatch(updateFlowRes(res))
        dispatch(setLoadingType(null))
    } catch(error) {
        dispatch(setLoadingType(null))
        console.log('sign waiver error', error)
        dispatch(updateError(error.error.messageToUser))
    }
}

export const getSuccessProp = ({navigate}) => {
    const flowName = store.getState().stepper.flowName
    const flowRes = store.getState().stepper.flowRes
    const type = getRegisterCtaInfo()
    const language = store.getState().site.language
    const schedule = flowRes?.schedule_info ?? flowRes
    console.log('getSuccessProp flowRes',flowRes)

    switch (flowName) {
        case flowOptions.REGISTER:
            return {
                header: t('register-success-header'),
                subheader: t('register-success-subheader'),
                ctaText: t(`success-cta-${type}`),
                svgType: 'register',
                onCTAClick: type ? () => onLinkClicked(`/${type}`, navigate) : null
            };
        case flowOptions.REGISTER_WITHOUT_MEMBERSHIP:
            return {
                header: t('register-wo-membership-success-header'),
                subheader: t('register-wo-membership-success-subheader'),
                ctaText: t(`success-cta-${type}`),
                svgType: 'register',
                onCTAClick: type ? () => onLinkClicked(`/${type}`, navigate) : null
            };
        case flowOptions.MEMBERSHIP_PURCHASE:
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
        case flowOptions.PRODUCT_PURCHASE:
        case flowOptions.DIRECT_PRODUCT_PURCHASE:
            const isProduct = flowName === flowOptions.PRODUCT_PURCHASE || flowName === flowOptions.DIRECT_PRODUCT_PURCHASE
            const itemName = flowRes?.memberships ? flowRes.memberships[0]?.membership_types?.name : '' //todo fix for multi items in cart
            const isCart = flowRes?.memberships && flowRes?.memberships.length > 1
            return {
                header: t(`${isProduct ? 'product' : 'membership'}-success-header`),
                subheader: t(`${isProduct ? 'product' : 'membership'}-success-subheader${isCart ? '-cart' : ''}`, {name: itemName}),
                ctaText: t(`success-cta-${type}`),
                svgType: isProduct ? 'shop' : 'membership',
                onCTAClick: type ? () => window.open(`/${type}`) : null
            };
        case flowOptions.GROUP_SESSION:
        case flowOptions.DIRECT_GROUP_SESSION:
            return {
                header: t('group-session-success-header'),
                subheader: t('group-session-success-subheader', {
                    date: dayjs(schedule.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}, YYYY`),
                    time: dayjs(schedule.time, 'HH:mm').format(schedule.locations_box.time_format ?? 'HH:mm'),
                    sessionName: schedule?.box_categories?.name ?? '',
                    interpolation: {'escapeValue': false}
                }),
                svgType: 'schedule',
                onCTAClick: null
            }
        case flowOptions.DIRECT_TRIAL_GROUP:
            return {
                header: t('trial-class-success-header'),
                subheader: t(`trial-class-success-subheader`, {
                    date: dayjs(schedule.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}, YYYY`),
                    time: dayjs(schedule.time, 'HH:mm').format(schedule.locations_box.time_format ?? 'HH:mm'),
                    sessionName: schedule?.box_categories?.name ?? '',
                    interpolation: {'escapeValue': false}
                }),
                svgType: 'schedule',
                onCTAClick: null,
                hideApp: true
            }
        case flowOptions.WAIVER:
            return {
                header: t('waiver-success-header'),
                subheader: t('waiver-success-subheader'),
                ctaText: t(`success-cta-${type}`),
                svgType: 'waiver',
                onCTAClick: type ? () => window.open(`/${type}`) : null
            }
        case flowOptions.STAFF_APPOINTMENT:
        case flowOptions.SPACE_APPOINTMENT:
            return {
                header: t(`appointment-success-header`),
                subheader: t(`${flowName}-success-subheader`, {
                    date: dayjs(schedule.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}, YYYY`),
                    time: dayjs(schedule.time, 'HH:mm').format(schedule.locations_box.time_format ?? 'HH:mm'),
                    sessionName: schedule?.box_categories?.name ?? '',
                    staffOrSpace: flowOptions.STAFF_APPOINTMENT ? schedule?.coach?.full_name : schedule?.spaces?.name
                }),
                svgType: 'schedule',
                onCTAClick: null
            }
        case flowOptions.COURSE:
        case flowOptions.DIRECT_COURSE:
        case flowOptions.COURSE_CHILD_REGISTRATION:
            const flags = store.getState().stepper.flags
            const location = store.getState().site.selectedLocation
            const course = flags.courseInfo
            let days = sortCourseEventDays(course.series_events).map(day => {
                return t('event-day-time', {day: t(`day-${day.day_of_week}`), time: dayjs(day.start, DB_TIME_FORMAT).format(location.time_format)})
            })
            const endDateStr = course.end_date ? ` - ${dayjs(course.end_date, DB_DATE_FORMAT).format(location.date_format)}` : '';
            const startDate = (course.allow_mid_booking && course.has_started) ? dayjs().format(location.date_format) : dayjs(course.start_date, DB_DATE_FORMAT).format(location.date_format)
            return {
                header: t(`course-success-header`),
                subheader: t(`course-success-subheader`, {
                    dateRange: `${startDate}${endDateStr}`,
                    days: days.join(', '),
                    courseName: course?.series_name ?? '',
                    interpolation: {'escapeValue': false}
                }),
                svgType: 'schedule',
                onCTAClick: null
            }
        default:
            return {};
    }
}

const getRegisterCtaInfo = () => {
    let type = null
    const pages = getBoxPageOptions()
    if(pages.hasClasses) {
        type = 'group'
    }
    else if(pages.hasAvailabilities) {
        type = 'appointment'
    }
    else if(pages.hasCourses) {
        type = 'course'
    }
    else if(pages.hasProducts) {
        type = 'shop'
    }
    return type;
}

const convertValuesToServerInsertParams = (values) => {
    const box = store.getState().site.box
    const flowName = store.getState().stepper.flowName
    const isAvailability = flowName === flowOptions.STAFF_APPOINTMENT || flowName === flowOptions.SPACE_APPOINTMENT;
    let extras = null

    if(values.payForSlot) {
        extras = {
            payForSlot: true,
            purchaseData: {
                cart: values.payment_info.cart,
                payment_object: values.payment_info,
                boxes_id: box.id
            }
        }
    }

    if(isAvailability) {
        extras = extras ? {...extras, ...getAvailabilityExtras(values.schedule)} : getAvailabilityExtras(values.schedule)
    }

    return {
        extras: extras,
        membership_user_id: values.id,
        schedule_id: values.schedule.id
    }
}

const handleExisingUserActions = async (values, dispatch) => {
    const params = convertValuesToServerInsertParams(values)
    const flowName = store.getState().stepper.flowName
    const isAvailability = flowName === flowOptions.STAFF_APPOINTMENT || flowName === flowOptions.SPACE_APPOINTMENT;
    let res = {};
    switch (values.schedule.booking_option) {
        case bookingTypes.INSERT_SCHEDULE_USER:
            res = await apiAction('scheduleUser/insert', 'post', params);
            res = values.payForSlot || isAvailability ? res.data : {...res.data, showSuccessToast: {title: t('success-booking-header'), content: t('success-booking-content')}}
            if(values.payForSlot) {
                handlePurchasePixel(values.payment_info.cart)
            }
            break;
        case bookingTypes.INSERT_STAND_BY:
            res = await apiAction('scheduleStandBy/insert', 'post', params);
            res = {...res.data, showSuccessToast: {title: t('success-standby-header'), content: t('success-standby-content')}}
            break;
        case bookingTypes.CANCEL_WAIT_LIST:
            res = await apiAction('scheduleStandBy/delete', 'post', {schedule_stand_by_id: values.schedule.user_in_standby});
            res = {...res.data, showSuccessToast: {title: t('success-cancel-standby-header'), content: t('success-cancel-standby-content')}}
            break;
        case bookingTypes.CANCEL_SCHEDULE_USER:
            let lateCancellation = false
            try {
                await apiAction(`scheduleUser/checkLateCancel`, 'post', {schedule_id: values.schedule.id});
            } catch (error) {
                lateCancellation = error.error.code === 513
            }
            res = await apiAction('scheduleUser/delete', 'post', {schedule_user_id: values.schedule.user_booked, schedule_id: values.schedule.id, late_cancel: lateCancellation});
            res = {...res.data, showSuccessToast: {title: t('success-cancel-booking-header'), content: t('success-cancel-booking-content')}}
            break;
        default:
            break;
    }
    dispatch(updateFlowRes(res))
}

const handleBookAndRegisterNewUser = async (values, dispatch, isTrial = false) => {
    const box = store.getState().site.box
    const flowName = store.getState().stepper.flowName
    const isAvailability = flowName === flowOptions.STAFF_APPOINTMENT || flowName === flowOptions.SPACE_APPOINTMENT;
    let extras = {
        payForSlot: true,
        purchaseData: {
            cart: values.payment_info.cart,
            payment_object: values.payment_info,
            boxes_id: box.id
        }
    }

    if(isAvailability) {
        extras = {...extras, ...getAvailabilityExtras(values.schedule)}
    }

    const params = {
        ...values,
        isTrial: isTrial,
        boxes_id: box.id,
        extras: extras
    }
    //remove double props (it's inside extras)
    delete params.payment_info
    delete params.payForSlot
    delete params.id
    delete params.cart


    const res = await apiAction(`registerUser`, 'post', params);
    dispatch(updateFlowRes(res))
    if(!isTrial) dispatch(addTokenAfterRegister(res))
    handlePurchasePixel(extras.purchaseData.cart)
}

const getAvailabilityExtras = (slot) => {
    return {
        availability: true,
        id: slot.id,
        box_categories_id: slot.box_categories.id,
        box_fk: slot.box_fk,
        date: slot.date,
        time: slot.time,
        end_time: slot.end_time,
        end_time_with_break: slot.end_time_with_break,
        coach_ub_id: slot.coach?.id, // Just for validations, will get data from DB
        spaces_id: slot.space?.id
    }
}

const handlePurchasePixel = (cart) => {
    try {
        if(cart) {
            const info = cart.reduce((acc, item) => {
                acc.totalPrice += item.price
                acc.contents = [...acc.contents, { id: item.name, quantity: item.quantity ?? 1, item_price: item.price }]
                acc.ids = [...acc.ids, JSON.parse(JSON.stringify(item.name))] //ids are the membership types names (the actual mt id means nothing to the box). also we do the parsing because otherwise hebrew chars are replaced for their coded values. this for some reason doesn't work in "contents".
                return acc
            }, {contents: [], totalPrice: 0, ids: []})
            const params = {
                value: info.totalPrice, //this is the original pricing of each membership and not the actual price they paid! (aka no promo discounts etc.)
                content_ids: info.ids,
                num_items: info.ids.length,
                contents: info.contents
            }
            console.log('handlePurchasePixel params', params)
            sendPixelEvent(pixelEvents.PURCHASE, params)
        }
    } catch (error) {
        console.log('error in getMembershipsDataForPixel', error)
    }
}

//</editor-fold>

//<editor-fold desc="export helpers">

export const showStepsProgress = {
    REGISTER: true,
    MEMBERSHIP_PURCHASE: false,
    DIRECT_MEMBERSHIP_PURCHASE: false,
    WAIVER: false
}

export const getDirectItemCta = (dispatch, params, values) => {
    const flowName = store.getState().stepper.flowName
    switch (flowName) {
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            const urlSearchParams = new URLSearchParams(window.location.search);
            const urlParams = Object.fromEntries(urlSearchParams.entries());
            const globalLocation = store.getState().site.selectedLocation
            const loggedIn = store.getState().auth.loggedIn
            const cartFirstItem = values.cart && values.cart.length ? values.cart[0] : null
            console.log('in1', globalLocation?.id, cartFirstItem, urlParams)
            if(urlParams.overrideLocation === 'true' && !loggedIn && cartFirstItem && cartFirstItem?.payment_locations && !cartFirstItem.payment_locations?.includes(globalLocation?.id)) {
                console.log('in2', globalLocation.id, values)
                return {text: t('membership-wrong-location-hp')}
            }

            return {text: t('specific-page-cta'), onClick: () => {
                    if(cartFirstItem.type === membershipTypes.PUNCH_CARD && cartFirstItem.allow_quantity) {
                        dispatch(openModal({modalName: SELECT_QUANTITY_MODAL}))
                    } else {
                        handleMembershipStartDate(cartFirstItem, dispatch)
                    }
                }
            }
        case flowOptions.DIRECT_GROUP_SESSION:
            return {text: getBookingCtaText(params.action), onClick: params.action === bookingTypes.PAST ? null : params.onCtaClick}
        case flowOptions.DIRECT_COURSE:
            const course = params.course
            if(!course) return {}
            let text = 'specific-page-cta'
            if(course.isHugFull) text = 'full-course'
            if(course.past && !course.allow_mid_booking) text = 'past-course'
            if(course.end_date && dayjs(course.end_date, DB_DATE_FORMAT).diff(dayjs(), 'days') < 0) text = 'course-over'
            if(course.isBookedUser) text = 'user-booked-to-course'
            return {
                text: t(text),
                onClick: text !== 'specific-page-cta' ? null : params.onCtaClick
            }
        default:
            return {text: t('specific-page-cta'), onClick: () => null}
    }
}

export const getDirectItemDescriptionComponent = (values) => {
    const flowName = store.getState().stepper.flowName
    switch (flowName) {
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            const cartFirstItem = values.cart && values.cart.length ? values.cart[0] : null
            return <MembershipDescription membership={cartFirstItem}/>
        case flowOptions.DIRECT_GROUP_SESSION:
            return <GroupSessionDescription session={values.schedule}/>
        case flowOptions.DIRECT_COURSE:
            return <CourseDescription course={values.course}/>
        default:
            return null
    }
}

export const getDirectItemAdditionalInformationComponent = (values) => {
    const flowName = store.getState().stepper.flowName
    switch (flowName) {
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            const cartFirstItem = values.cart && values.cart.length ? values.cart[0] : null
            return <MembershipInfo membership={cartFirstItem}/>
        case flowOptions.DIRECT_GROUP_SESSION:
            return <GroupSessionInfo session={values.schedule}/>
        case flowOptions.DIRECT_COURSE:
            return <CourseInfo course={values.course}/>
        default:
            return null
    }
}
export const getDirectItemOnQuantityRes = () => {
    const flowName = store.getState().stepper.flowName
    switch (flowName) {
        case flowOptions.DIRECT_MEMBERSHIP_PURCHASE:
            return (quantity, values, setFieldValue, dispatch) => {
                const cartFirstItem = values.cart && values.cart.length ? values.cart[0] : {}
                setFieldValue('cart', [{...cartFirstItem, quantity: quantity}])
                dispatch(updateModalRes({modalName: SELECT_QUANTITY_MODAL, res: null}))
                setTimeout(() => handleMembershipStartDate(cartFirstItem, dispatch), 100)
            }
        case flowOptions.DIRECT_GROUP_SESSION:
        case flowOptions.DIRECT_COURSE:
            return null;
        default:
            return null
    }
}

export const overrideValues = (flowName) => {
    switch (flowName) {
        case flowOptions.REGISTER_WITHOUT_MEMBERSHIP:
        case flowOptions.GROUP_SESSION:
        case flowOptions.DIRECT_GROUP_SESSION:
        case flowOptions.STAFF_APPOINTMENT:
        case flowOptions.SPACE_APPOINTMENT:
        case flowOptions.COURSE_CHILD_REGISTRATION:
            return false
        default:
            return true
    }
}

//</editor-fold>
