import React, {useMemo} from 'react';
import {SmallHeader, SmallLogo} from "../styles/globalStyles";
import {getBoxLogoReplacement, onLinkClicked} from "../helpers/functions";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useScreenSize from "../helpers/hooks/useScreenSize";

const Logo = (props) => {
    const {onClick} = props;
    const box = useSelector(state => state.site.box)
    const name = useMemo(() => box ? getBoxLogoReplacement(box.name) : '', [box]);
    const navigate = useNavigate();
    const {isMobile} = useScreenSize();

    if(!box) return <></>

    const onLogoClick = () => {
        // onLinkClicked('/', navigate)
        // if(onClick) onClick()
    }

    return (
        <>
            {
                box.cloudinary_image ?
                    <SmallLogo mobile={isMobile} src={box?.cloudinary_image} alt="logo" onClick={onLogoClick}/>
                    :
                    <div onClick={onLogoClick}><SmallHeader fontSize={'22px'}>{name}</SmallHeader></div>
            }
        </>
    )
};

export default Logo;