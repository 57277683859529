import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import {useParams, useLocation} from "react-router-dom";
import Logo from "../../components/Logo";

const usePageHeader = () => {
    const [header, setHeader] = useState('');
    const { id } = useParams();
    const location = useLocation();

    useEffect(() => {
        let header = ''
        if(location.pathname === '/') {
            header = t('home')
        } else if(location.pathname === '/register') {
            header = t('register')
        } else if(location.pathname === '/membership') {
            header = t('memberships-plugin-header')
        } else if(location.pathname === '/staff-appointment') {
            header = t('staff-appointment-plugin-header')
        } else if(location.pathname === '/space-appointment') {
            header = t('space-appointment-plugin-header')
        } else if(location.pathname === '/group') {
            header = t('group-plugin-header')
        } else if(location.pathname === '/shop') {
            header = t('shop-plugin-header')
        } else if(location.pathname === '/course') {
            header = t('course-plugin-header')
        } else if(location.pathname === '/form') {
            header = t('form-plugin-header')
        }

        if (id) {
            header = <Logo/>
        }
        setHeader(header)

    }, [location]);


    return header
};

export default usePageHeader;