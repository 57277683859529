import React, {useEffect, useState} from 'react';

const Quantity = (props) => {
    const { onChange, max, min, initSelection } = props;
    const [counter, setCounter] = useState(initSelection ?? 1);

    useEffect(() => {
        onChange(counter)
    }, [counter]);

    return (
        <div className={'quantity-wrapper'}>
            <div className={'sign'} onClick={() => setCounter(prev => prev === (min ?? 1) ? prev : prev - 1)}>-</div>
            <div>{counter}</div>
            <div className={'sign'} onClick={() => setCounter(prev => (max && prev === max) ? prev : prev + 1)}>+</div>
        </div>
    );
};

export default Quantity;