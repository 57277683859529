import React, {useEffect} from 'react';
import {flowOptions, initFlow} from "../../flowManager/FlowOptions";
import {useDispatch, useSelector} from "react-redux";
import {setTargetName, updateFlags, updateStepper} from "@arboxappv4/shared/src/redux/stepperSlice";
import {stepOptions} from "../../flowManager/StepOptions";

const useFlow = (props) => {
    const { flowSelection, initProps } = props;
    const flags = useSelector(state => state.stepper.flags)
    const flowName = useSelector(state => state.stepper.flowName)
    const steps = useSelector(state => state.stepper.steps)
    const flowRes = useSelector(state => state.stepper.flowRes)
    const currStep = useSelector(state => state.stepper.currStep)
    const user = useSelector(state => state.user.data)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const box = useSelector(state => state.site.box)
    const dispatch = useDispatch()

    useEffect(() => {
        const flow = initFlow(flowSelection)
        setTimeout(() => dispatch(updateStepper(flow)), 10) // we need this otherwise, initial values useeffect is called twice and remove previous values
    }, [flowSelection]);

    useEffect(() => {
        if(initProps?.target) {
            dispatch(setTargetName(initProps.target.nameForTab))
        } else {
            dispatch(setTargetName(null))
        }
    }, [initProps]);

    useEffect(() => {
        if(flags.continueWithoutMembership && (flowName === flowOptions.REGISTER)) {
            const flow = initFlow(flowOptions.REGISTER_WITHOUT_MEMBERSHIP)
            dispatch(updateStepper(flow))
        }
    }, [flags.continueWithoutMembership]);

    useEffect(() => {
        if(flowName === flowOptions.REGISTER_WITHOUT_MEMBERSHIP && flags.continueWithoutMembership) {
            dispatch(updateFlags({continueWithoutMembership: false}))
        }
    }, [flowName]);

    useEffect(() => {
        if(![flowOptions.REGISTER, flowOptions.WAIVER].includes(flowName) && currStep !== stepOptions.SUCCESS && steps && currStep !== steps[0]) {
            if((user && loggedIn && !flags.loggedIn) || (!user && !loggedIn && flags.loggedIn)) {
                window.location.reload()
            }
        }
    }, [user, loggedIn]);

    useEffect(() => {
        // if user is not logged in, the only option for him to book is by pay for slot, so the steps are static.
        // if user is logged in, payment step will depend on the users membership choice (membership user or pay for slot).
        if([flowOptions.GROUP_SESSION, flowOptions.DIRECT_GROUP_SESSION, flowOptions.STAFF_APPOINTMENT, flowOptions.SPACE_APPOINTMENT].includes(flowName) && typeof flags.payForSlot === 'boolean' && loggedIn) {
            let newSteps
            if(flags.payForSlot) {
                if(!steps.includes(stepOptions.PAYMENT)) {
                    newSteps = [...steps, stepOptions.PAYMENT]
                }
            } else {
                newSteps = steps.filter(step => step !== stepOptions.PAYMENT)
            }
            dispatch(updateStepper({steps: newSteps, flags: {...flags, stepsUpdated: true}}))
        }
    }, [flags.payForSlot]);

    useEffect(() => {
        if([flowOptions.GROUP_SESSION, flowOptions.DIRECT_GROUP_SESSION].includes(flowName) && flowRes?.showSuccessToast) {
            const flow = initFlow(flowName)
            dispatch(updateStepper(flow))
        }
    }, [flowRes]);

    useEffect(() => {
        if([flowOptions.COURSE, flowOptions.DIRECT_COURSE].includes(flowName) && flags.isChildRegistration) {
            const flow = initFlow(flowOptions.COURSE_CHILD_REGISTRATION)
            dispatch(updateStepper(flow))
        } else if(flowOptions.COURSE_CHILD_REGISTRATION === flowName && !flags.isChildRegistration) {
            const flow = initFlow(flowOptions.COURSE)
            dispatch(updateStepper(flow))
        }
    }, [flags.isChildRegistration]);

    useEffect(() => {
        if(flowName === flowOptions.COURSE_CHILD_REGISTRATION) {
            let newSteps = [...steps]
            if(flags.isNewChild) {
                if (box.digital_form_id) {
                    const insertIndex = newSteps.findIndex(step => step === stepOptions.COURSE_CHILD_SELECTION)
                    newSteps.splice(insertIndex + 1, 0, stepOptions.WAIVER);
                    dispatch(updateStepper({steps: newSteps}))
                }
            } else {
                let newSteps = steps.filter(step => step !== stepOptions.WAIVER)
                if(newSteps.length !== steps.length) {
                    dispatch(updateStepper({steps: newSteps}))
                }
                dispatch(updateFlags({isNewChild: false}))
            }
        }
    }, [flags.isNewChild]);
};

export default useFlow;