import React, {useMemo} from 'react';
import {
    Btn,
    FlexRowSection,
    HeaderButton,
    Header as HeaderText, HeaderButtonActive,
} from "../../styles/globalStyles";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {SITE_REFERER} from "@arboxappv4/shared/src/constants/constants";
import {LOGIN_MODAL, MOBILE_MENU_DRAWER, openModal} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {logout} from "@arboxappv4/shared/src/redux/authSlice";
import {
    getHourIcon,
    getLocationsForPurchase,
    getOptionalRoutesArray,
    isLocationIL, onLinkClicked
} from "../../helpers/functions";
import iconGenerator from "../../helpers/IconGenerator";
import {Dropdown} from "antd";
import i18next, {t} from "i18next";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {changeLangByCode, isRTL} from "../../helpers/i18n";
import usePageHeader from "../../helpers/hooks/usePageHeader";
import Logo from "../../components/Logo";

const Header = (props) => {
    const { width } = props;
    const box = useSelector(state => state.site.box)
    const referrer = useSelector(state => state.site.referrer)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const language = useSelector(state => state.site.language)
    const location = useSelector(state => state.site.selectedLocation)

    const navigate = useNavigate();
    const {isMobile} = useScreenSize();
    const isSite = referrer === SITE_REFERER
    const dispatch = useDispatch()
    const pageHeader = usePageHeader()

    const pageInfo = useMemo(() => box ? getOptionalRoutesArray() : null, [box]);
    const locations = useMemo(() => getLocationsForPurchase(),[box])


    const userMenu = useMemo(() => {
        let options = []
        if(isLocationIL()) {
            options.push({
                key: 'lang',
                label: t('language'),
                children: [
                    {
                        key: 'lang-en',
                        label: (
                            <FlexRowSection gap={'20px'}>
                                {t('english')}
                                {language?.code === 'en' && <FontAwesomeIcon icon={light('check')} size={'xs'}/>}
                            </FlexRowSection>),
                    },
                    {
                        key: 'lang-he',
                        label: (
                            <FlexRowSection gap={'20px'}>
                                {t('hebrew')}
                                {language?.code === 'he' && <FontAwesomeIcon icon={light('check')} size={'xs'}/>}
                            </FlexRowSection>),
                    },
                ],
            }, {
                type: 'divider'
            })
        }
        options.push({key: 'logout', label: t('logout')})
        return options
    }, [language, location]);

    const menuClicked = ({key}) => {
        switch (key) {
            case 'logout':
                logoutUser()
                break;
            case 'lang-en':
                changeLangByCode('en', dispatch, navigate)
                break;
            case 'lang-he':
                changeLangByCode('he', dispatch, navigate)
                break;
        }
    }

    const showLogin = () => {
        dispatch(openModal({modalName: LOGIN_MODAL}))
    }

    const showMobileMenu = () => {
        dispatch(openModal({modalName: MOBILE_MENU_DRAWER}))
    }

    const logoutUser = () => {
        dispatch(logout())
        onLinkClicked('', navigate)
    }

    const getHeaderButton = (item) => {
        if(item.to) {
            const isActive = window.location.pathname.includes(item.to)
            if(isActive) return <HeaderButtonActive target={'_blank'} href={`/${item.to}`} onClick={(e) => {e.preventDefault(); onLinkClicked(item.to, navigate)}}>{t(`header-${item.name}`)}</HeaderButtonActive>
            return <HeaderButton target={'_blank'} key={`header-item-${item.name}`} href={`/${item.to}`} onClick={(e) => {e.preventDefault(); onLinkClicked(item.to, navigate)}}>{t(`header-${item.name}`)}</HeaderButton>
        }
        if(item.children) {
            const isActive = item.children.some(subItem => window.location.pathname.includes(subItem.key))
            return (
                <Dropdown menu={{items: item.children, onClick: (subItem) => onLinkClicked(subItem.key, navigate)}}>
                    {isActive ?
                        <HeaderButtonActive key={`header-item-${item.name}`}>{t(`header-${item.name}`)}</HeaderButtonActive>
                        :
                        <HeaderButton key={`header-item-${item.name}`}>{t(`header-${item.name}`)}</HeaderButton>
                    }
                </Dropdown>
            )
        }
    }

    return (
        <Wrapper width={isMobile ? '100%' : width} isMobile={isMobile}>
            {isSite ?
                <Logo/>
                :
                <>{typeof pageHeader === 'string' ? <HeaderText>{pageHeader}</HeaderText> : pageHeader}</>
            }
            {isMobile ?
                <div onClick={showMobileMenu}><FontAwesomeIcon icon={light('bars')} size={'lg'}/></div>
                :
                <>
                    { isSite && pageInfo &&
                        <ActionWrapper gap={'40px'}>
                            {pageInfo.map(item => getHeaderButton(item))}
                        </ActionWrapper>
                    }
                    <ActionWrapper gap={'40px'}>
                        {loggedIn && userBox ?
                            <Dropdown menu={{items: userMenu, onClick: menuClicked, style: {width: 150, direction: i18next.dir()}, expandIcon: <FontAwesomeIcon icon={light('chevron-right')} color={Colors.siteBlack} size={'xs'} flip={isRTL() ? 'horizontal' : 'vertical'}/>}} trigger={['click']}>
                                <LoggedInUser gap={'10px'}>
                                    <FontAwesomeIcon icon={iconGenerator({name: getHourIcon()})}/>
                                    {t('loggedInMessage', {name: userBox.first_name})}
                                </LoggedInUser>
                            </Dropdown>
                            :
                            <>
                                <LoginButton onClick={showLogin}>{t('login')}</LoginButton>
                                {locations.length > 0 && <Btn type={'primary'} onClick={() => onLinkClicked('/register', navigate)}>{t('register')}</Btn>}
                            </>
                        }
                    </ActionWrapper>
                </>
            }
        </Wrapper>
    );
};

export default Header;

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: ${({width}) => width};
    align-items: center;
    justify-content: space-between;
    padding: 0 ${({isMobile}) => isMobile ? '16px' : 0};
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props?.gap};
    align-items: center
`;

const LoggedInUser = styled(FlexRowSection)`
    font-weight: bold;
    cursor: pointer;

`;

const LoginButton = styled((props) => <a {...props}>{props.children}</a>)`
    text-transform: unset;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${Colors.siteBlack};
    :hover {
        color: ${Colors.siteBlackHover};
    }
`;