import React, {useEffect, useState} from 'react';
import {
    LOGIN_MODAL,
    openModal,
    SELECT_DATE_MODAL,
    updateModalRes
} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {useDispatch, useSelector} from "react-redux";
import {updateToastMessage} from "@arboxappv4/shared/src/redux/stepperSlice";
import {t} from "i18next";

const useMembershipStartDate = (props) => {
    const { values, setFieldValue, handleSubmit, blockOnLoggedOut, isCart } = props;
    const selectDateRes = useSelector(state => state.modalManager[SELECT_DATE_MODAL].processRes)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const flags = useSelector(state => state.stepper.flags)
    const dispatch = useDispatch()

    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [fromLoginPopup, setFromLoginPopup] = useState(false);

    useEffect(() => {
        if(selectDateRes) {
            if(isCart) {
                const newCart = values.cart?.map((item, i) => {
                    if(i === values.cart.length - 1) {
                        if(!item.start) dispatch(updateToastMessage({content: t('cart-item-added', {name: item.name, interpolation: {'escapeValue': false}}), title: t('item-add-success')}))
                        else dispatch(updateToastMessage({content: t('cart-item-updated', {name: item.name, quantity: item.quantity, interpolation: {'escapeValue': false}}), title: t('item-update-success')}))
                        return {...item, start: selectDateRes}
                    }
                    return item
                })
                setFieldValue('cart', newCart)
            } else {
                setFieldValue('cart', [{...values.cart[0], start: selectDateRes}])
            }
            setReadyToSubmit(true)
            dispatch(updateModalRes({modalName: SELECT_DATE_MODAL, res: null}))
        }
    }, [selectDateRes]);

    useEffect(() => {
        const firstCartItem = values.cart && values.cart.length ? values.cart[0] : null
        if(firstCartItem && readyToSubmit && !isCart) {
            if(loggedIn || !blockOnLoggedOut) {
                handleSubmit(values)
                setReadyToSubmit(false)
            } else {
                setFromLoginPopup(true)
                dispatch(openModal({modalName: LOGIN_MODAL, props: {membership: firstCartItem}, onClose: () => setReadyToSubmit(false)}))
            }
        }
    }, [values.cart, readyToSubmit]);

    useEffect(() => {
        if(loggedIn && fromLoginPopup && values.cart && values.cart.length && !isCart) {
            setFromLoginPopup(false)
            handleSubmit(values)
        }
    }, [loggedIn, fromLoginPopup]);

    useEffect(() => {
        console.log('useMembershipStartDate values', values)
    }, [values]);

    useEffect(() => {
        //submit only after we know for sure that the new flow has been initialized (that is why flags.continueWithoutMembership=false)
        if(values.continueWithoutMembership && !flags.continueWithoutMembership && !isCart) {
            handleSubmit(values)
        }
    }, [values.continueWithoutMembership, flags.continueWithoutMembership]);


};

export default useMembershipStartDate;