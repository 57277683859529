import React, {useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import {useSelector} from "react-redux";
import {store} from "@arboxappv4/shared/src/redux/store";
import { useLocation } from 'react-router-dom';

const options = {
    autoConfig: true,
    debug: true, // Set to true to see console logs, TURN ON ONLY ON LOCAL ENV DEBUG
    testEvents: true // TURN ON ONLY ON LOCAL ENV DEBUG
};

export const pixelEvents = {
    PURCHASE: 'Purchase',
    ADD_TO_CART: 'AddToCart',
    LEAD: 'Lead',
    VIEW_CONTENT: 'ViewContent',
    COMPLETE_REGISTRATION_NO_MEMBERSHIP: 'CompleteRegistration'
}

export const sendPixelEvent = (eventName, data = {}) => {
    try {
        console.log('sendPixelEvent', eventName, data)
        const globalLocation = store.getState().site.selectedLocation
        ReactPixel.track(eventName, {
            currency: globalLocation.currency,
            test_event_code: 'TEST94283', //my local test, wouldn't work anywhere else, each pixel has it's own test_event_code
            ...data
        });
    } catch (e) {
        console.log('error sending pixel event', e, eventName, data)
    }
}

const UsePixel = () => {
    const box = useSelector(state => state.site.box)
    const location = useLocation();

    useEffect(() => {
        if(box?.pixel_id) {
            initFacebookPixel(box.pixel_id)
        }
    }, [box]);

    useEffect(() => {
        if (box?.pixel_id) {
            ReactPixel.pageView();
        }
    }, [location.pathname, box?.pixel_id]);

    const initFacebookPixel = (pixelID) => {
        console.log('initFacebookPixel', pixelID)
        ReactPixel.init(pixelID, undefined, options);
    };
};

export default UsePixel;