import React, {lazy, useEffect, useMemo, useRef, useState} from 'react';
import {
    Btn,
    FlexColumnSection,
    FlexRowSection,
    SmallText,
    FlexDiv,
    ErrorText,
    EmptyFlexDiv
} from "../../styles/globalStyles";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import FormInput from "../../components/UI/FormInput";
import {t} from "i18next";
import {checkIfDateAfterToday, getLocationsForPurchase} from "../../helpers/functions";
import {LOGIN_MODAL, openModal} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "antd";
import CustomSelect from "../../components/UI/CustomSelect";
import WhatsNextBox from "../../components/WhatsNextBox";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {setError, setLoadingType} from "@arboxappv4/shared/src/redux/siteSlice";
import {DB_DATE_FORMAT, LOADING_PAGE} from "@arboxappv4/shared/src/constants/constants";
import ReCAPTCHA from "react-google-recaptcha";
import dayjs from "dayjs";
import CustomFieldSwitcher from "../../components/UI/CustomFieldSwitcher";
import {customFieldFlowNames} from "@arboxappv4/shared/src/helpers/constants";
const CustomDatePicker = lazy(() => import(/* webpackChunkName: "CustomDatePicker" */'../../components/UI/CustomDatePicker'));

const PersonalDetails = (props) => {
    const { handleSubmit, values, errors, setFieldValue, touched, hideLocation, helperText, isInnerComponent, nextBtnText, validationSchema, cfFlowName, HPGroupId } = props;
    const box = useSelector(state => state.site.box)
    const selectedLocation = useSelector(state => state.site.selectedLocation)
    const [emailError, setEmailError] = useState(null);
    const [isValidRecaptcha, setIsValidRecaptcha] = useState(process.env.NODE_ENV !== 'production');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()
    const recaptchaRef = useRef()

    const locations = useMemo(() => getLocationsForPurchase(true, HPGroupId),[box])
    const customFieldsDataPerLocation = useMemo(() => box?.locations_box?.find(location => location.id === values.locations_box_fk)?.custom_field, [values.locations_box_fk,box]);

    useEffect(() => {
        const flowCustomFields = customFieldsDataPerLocation?.filter(cf => cf?.custom_field_flow.some(cf_flow => cf_flow.flow.name === (cfFlowName ?? customFieldFlowNames.USER)))
        const newFields = flowCustomFields?.map(field => {
            if(values.customFields) {
                const old = values.customFields.find(oldField => oldField.id === field.id)
                if (old) {
                    return {...field, value: old.value}
                }
            }
            return field;
        })
        setTimeout(() => setFieldValue('customFields', newFields),10);
    }, [customFieldsDataPerLocation]);

    useEffect(() => {
        if(locations && locations.length === 1 && Object.keys(values).length) {
            setFieldValue('locations_box_fk', locations[0].id)
        }
    }, [locations, values]);

    useEffect(() => {
        if(selectedLocation && locations && !values.locations_box_fk && !HPGroupId) {
            if(locations.some(location => location.id === selectedLocation.id)) {
                setFieldValue('locations_box_fk', selectedLocation.id)
            }
        }
    }, [selectedLocation, locations, values.locations_box_fk]);

    useEffect(() => {
        if(emailError) {
            setEmailError(null)
        }
    }, [values.email]);

    useEffect(() => {
        // console.log('errors',errors, touched)
        // console.log('values',values)
    }, [values, errors, touched]);

    const validateEmailAndSubmit = async () => {
        setSubmitted(true)
        try {
            setEmailError(errors.email)
            if (values.email && Object.keys(errors).length === 0) {
                setLoading(true)
                const email = values.email.replace(' ', '')
                let allowedToConvert = !values.lead_id;
                if(values.lead_id) {
                    allowedToConvert = await apiAction('checkConvertLeadMail', 'post', {email: email, user_id: values.lead_id});
                }
                if(!allowedToConvert) {
                    setEmailError(t('not-allowed-to-convert'))
                } else {
                    const response = await apiAction(`site/register/mailExist`, 'post', {email: email, boxId: box?.id});
                    if(response?.email) {
                        const valid = handleExisingUserBox(response)
                        if(valid) await submitStep()
                    } else {
                        await submitStep()
                    }
                }
                setLoading(false)
            } else {
                await submitStep()
            }
        } catch(error) {
            console.log('error', error)
        }
    }

    const submitStep = async () => {
        if(process.env.NODE_ENV !== 'production') {
            handleSubmit()
        } else {
            if(isValidRecaptcha) handleSubmit()
            else {
                const token = await recaptchaRef.current?.executeAsync();
                const isValid = await apiAction(`register/validateRecaptcha`, 'post', {token});
                setIsValidRecaptcha(isValid)
                if (isValid) {
                    handleSubmit()
                } else {
                    dispatch(setError(t('register-recaptcha-error')))
                }
            }
        }
    }

    const handleExisingUserBox = (userData) => {
        let valid = true;
        if(userData.isDeleted) {
            setEmailError(t('user-box-deleted'))
            valid = false
        } else {
            if(userData.isLeadInBox) {
                setFieldValue('lead_id', userData.userFk)
            } else {
                setEmailError(
                    <span>
                        <ErrorText>{t('user-box-exist')}. </ErrorText>
                        <LoginToContinue onClick={() => dispatch(openModal({modalName: LOGIN_MODAL, props: {email: values.email}}))}>{t('login-to-continue')}</LoginToContinue>
                    </span>
                )
                valid = false
            }
        }
        return valid
    }

    const inputLabel = (name, label) => {
        if(validationSchema && validationSchema.fields[name] && !validationSchema.fields[name].spec.optional) {
            return `${label}*`
        }
        return label
    }

    return (
        <FlexRowSection justify={'space-between'} gap={'40px'} height={'100%'} align={'start'}>
            <FlexColumnExtended gap={'20px'} isMobile={isMobile} height={'100%'} isInnerComponent={isInnerComponent}>
                {isMobile && !isInnerComponent &&
                    <FlexRowSection gap={'5px'} height={'25px'} flex={'none'}>
                        <SmallText>{t('already-have-account')}</SmallText>
                        <SmallTextUnderline onClick={() => dispatch(openModal({modalName: LOGIN_MODAL}))}>{t('login')}</SmallTextUnderline>
                    </FlexRowSection>
                }
                <FlexColumnSection gap={'24px'} height={isMobile ? `calc(100% - ${25+38}px)` : 'auto'} overflow={'auto'} flex={1}>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('first_name', t('first-name'))} initValue={values.first_name} value={values.first_name} error={(submitted && errors.first_name) ? errors.first_name : null} onChangeCallback={(val) => setFieldValue('first_name', val)} autoComplete={'given-name'} id="firstName" flex={1}/>
                        <FormInput label={inputLabel('last_name', t('last-name'))} initValue={values.last_name} value={values.last_name} error={(submitted && errors.last_name) ? errors.last_name : null} onChangeCallback={(val) => setFieldValue('last_name', val)} autoComplete={'family-name'} id="lastName" flex={1}/>
                    </FlexDiv>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('email', t('email'))} initValue={values.email} value={values.email} error={(submitted && errors.email) ? errors.email : emailError} onChangeCallback={(val) => setFieldValue('email', val)} type={'email'} autoComplete={'email'} inputMode={'email'} id={'email'} flex={1} />
                        <FormInput label={inputLabel('phone', t('phone'))} initValue={values.phone} value={values.phone} error={(submitted && errors.phone) ? errors.phone : null} onChangeCallback={(val) => setFieldValue('phone', val)} type={'phone'} autoComplete={'phone'} id={'phone'} flex={1} inputMode={'tel'}/>
                    </FlexDiv>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('birthday', t('birth-date'))} value={values.birthday} error={(submitted && errors.birthday) ? errors.birthday : null} flex={1} showOptional={true}>
                            <CustomDatePicker onDateSelect={(val) => setFieldValue('birthday', val)}
                                              disabledDate={checkIfDateAfterToday}
                                              validRange={[dayjs('1920-01-01',DB_DATE_FORMAT), dayjs()]}
                                              defaultDate={values.birthday}
                            />
                        </FormInput>
                        <FormInput showOptional={true} label={inputLabel('gender', t('gender'))} value={values.gender} flex={1}>
                            <CustomSelect allowClear filterSort={(a,b) => a - b} onChange={(val) => setFieldValue('gender', val)} options={[{ label: t('male'), value: 'male' }, { label: t('female'), value: 'female' }]} defaultValue={values.gender} placeholder={t('select')} />
                        </FormInput>
                    </FlexDiv>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        {
                            'personal_id' in values &&
                            <FormInput label={inputLabel('personal_id', t('personal-id'))} value={values.personal_id} error={(touched.personal_id && errors.personal_id) ? errors.personal_id : null} onChangeCallback={(val) => setFieldValue('personal_id', val)} type={'phone'} inputMode={'tel'} flex={1}/>
                        }
                        {
                            !hideLocation && locations && locations.length > 1 ?
                            <FormInput label={inputLabel('locations_box_fk', t('location'))} value={values.locations_box_fk} error={(submitted && errors.locations_box_fk) ? errors.locations_box_fk : null} flex={1}>
                                <CustomSelect onChange={(val) => setFieldValue('locations_box_fk', val)} options={locations} defaultValue={values.locations_box_fk}/>
                            </FormInput> : <EmptyFlexDiv />
                        }
                    </FlexDiv>
                    {values.customFields && <CustomFieldSwitcher data={values?.customFields} setFieldValue={setFieldValue} touched={touched} errors={errors} values={values}/> }
                    <FlexColumnSection gap={'10px'} flex={'none'}>
                        <Checkbox checked={values.allow_mailing_list === 'yes'} onChange={(val) => setFieldValue('allow_mailing_list', val.target.checked ? 'yes' : 'no')} style={{width: 'fit-content'}}>
                            <SmallText>{t('allow-mail')}</SmallText>
                        </Checkbox>
                        <Checkbox checked={values.allow_sms === 'yes'} onChange={(val) => setFieldValue('allow_sms', val.target.checked ? 'yes' : 'no')} style={{width: 'fit-content'}}>
                            <SmallText>{t('allow-sms')}</SmallText>
                        </Checkbox>
                    </FlexColumnSection>
                    <div>
                        <span style={{fontSize: '14px'}}>{t('agree-to')} </span>
                        <SmallTextUnderline fontSize={'14px'} onClick={() => window.open('https://www.arboxapp.com/terms-and-conditions', '_blank')}>{t('terms-of-Use')}</SmallTextUnderline>
                        <span style={{fontSize: '14px'}}> {t('acknowledge-and-read')} </span>
                        <SmallTextUnderline fontSize={'14px'} onClick={() => window.open('https://www.arboxapp.com/privacy-policy', '_blank')}>{t('privacy-policy')}</SmallTextUnderline>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.SITE_REGISTER_RECAPTCHA}
                        size="invisible"
                    />
                </FlexColumnSection>
                <FlexRowSection height={'38px'} flex={'none'} justify={'end'}>
                    <Btn type={'primary'} onClick={validateEmailAndSubmit} width={isMobile ? '100%' : null} loading={loading}>{nextBtnText ?? t('continue')}</Btn>
                </FlexRowSection>
            </FlexColumnExtended>
            {!isMobile && !isInnerComponent && <WhatsNextBox text={helperText ?? t('personal-details-whats-next')}/>}
        </FlexRowSection>

    );
};

export default PersonalDetails;

const FlexColumnExtended = styled(FlexColumnSection)`
    padding: ${({isMobile, isInnerComponent}) => isMobile ? '0 16px' : isInnerComponent ? '16px' : '20px'};
    background: ${({isMobile}) => isMobile ? 'none' : Colors.white};
    box-sizing: border-box;
    border-radius: 5px;
`;
const SmallTextUnderline = styled.span`
    text-decoration: underline;
    font-size: ${({fontSize}) => fontSize ?? '16px'};
    font-weight: 400;
    cursor: pointer;
`;

const LoginToContinue = styled(SmallTextUnderline)`
    font-size: 14px;
    color: ${Colors.siteBlack}
`