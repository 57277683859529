import React, {useEffect, useMemo} from 'react';
import {Btn, FlexRowSection, SeparatorVerticalDiv} from "../styles/globalStyles";
import {t} from "i18next";
import useScreenSize from "../helpers/hooks/useScreenSize";
import {useSelector} from "react-redux";

const CartBanner = (props) => {
    const { values, handleSubmit } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const {isMobile} = useScreenSize();
    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const cart = useMemo(() => values?.cart ?? [], [values?.cart]);

    const getNumberOfItemsInCart = () => {
        return cart.reduce((acc, item) => acc + (item.quantity > 1 ? item.quantity : 1) + (item.addons?.length ?? 0), 0)
    }

    const calcTotalCart = () => {
        return cart.reduce((acc, item) => {
            let addonsTotal = 0
            if(item.addons?.length) {
                addonsTotal = item.addons.reduce((acc2, addon) => acc2 + (addon.price), 0)
            }
            return acc + ((item.price + addonsTotal) * (item.quantity > 1 ? item.quantity : 1))
        },0)
    }

    return (
        <FlexRowSection gap={'20px'} flex={'unset'} justify={isMobile ? 'space-between' : 'end'}>
            <span>
                <span style={{fontSize: '18px', fontWeight: 600}}>{t('total')}: {currencySymbol}{calcTotalCart()}</span>
                <span> ({getNumberOfItemsInCart()} {t('items')})</span>
            </span>
            <SeparatorVerticalDiv/>
            <Btn type={'primary'} onClick={handleSubmit} disabled={(values?.cart ?? []).length === 0} className={'site-primary-btn'}>{t('purchase')}</Btn>
        </FlexRowSection>
    );
};

export default CartBanner;